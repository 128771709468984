import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CombinedItinerary, CustomerInfo, IbannerImages, ItineraryInfo } from "./Interface.we";
import ApiRequest from "../../../components/src/ApiRequest";
import { CityData, CountryData } from "../../email-account-registration/src/Interface.web";
import { capitalizeFirstLetter, getAuthToken } from "../../../components/src/utils";
import { Categories } from "../../ProductDescription/src/Interface.web";
import { setStorageData } from "../../../framework/src/Utilities";
export const Colors = {
  inputLabel: "rgba(31, 31, 34, 0.6)",
  inputTextColor: "rgb(31, 31, 34)",
  borderGrey: "rgba(28, 28, 30, 0.3)",
  borderYellow: "rgb(205, 149, 12)",
  white: "#FFFFFF",
  modalBg: "rgb(243, 243, 243)",
};

const options = {
  title: "Select Image",
  storageOptions: {
    skipBackup: true,
    path: "images",
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getSellerDetailsMessageId: any;
  postSellerDetailsMessageId: any;
  sellerID: any;
  shopName: string;
  address: string;
  gstin: string;
  selectedServices: number[];
  showSuccessModal: boolean;
  token: string;
  lat: any;
  long: any;
  responseStatsModal: boolean;
  isSuccessIcon: boolean;
  responseModalMsg: string;
  customer_info: CustomerInfo;
  itinerary: ItineraryInfo;
  countryList: CountryData[];
  vibeList: Categories[];
  cityList: CityData[];
  season: IbannerImages[];
  energy: IbannerImages[];
  sleepingPreference: IbannerImages[];
  personalPreferenceVibes: IbannerImages[];
  selectedCategorySeason: null | string, // Only one can be selected
  selectedCategoryEnergy: null | string, // Only one can be selected
  selectedCategorySleeping: null | string, // Only one can be selected
  selectedCategoryVibes: string[],   // Multiple can be selected
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomformController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      getSellerDetailsMessageId: "",
      postSellerDetailsMessageId: "",
      sellerID: "",
      shopName: "",
      address: "",
      gstin: "",
      selectedServices: [],
      token: "",
      showSuccessModal: false,
      lat: 0,
      long: 0,
      responseStatsModal: false,
      isSuccessIcon: false,
      responseModalMsg: '',
      customer_info: {
        first_name: '',
        last_name: '',
        email: '',
        country_code: '',
        mobile_number: '',
      },
      itinerary: {
        type_of_help: '',
        have_date: '',
        from_date: undefined,
        to_date: undefined,
        persons: '',
        know_destination: '',
        country: '',
        city: '',
        have_buget: '',
        budget_amount: '',
        vibes: [],
      },
      countryList: [],
      vibeList: [],
      cityList: [],
      season: [],
      energy: [],
      sleepingPreference: [],
      personalPreferenceVibes: [],
      selectedCategorySeason: null,
      selectedCategoryEnergy: null,
      selectedCategorySleeping: null,
      selectedCategoryVibes: [],
      loading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getSellerDetail(token)
    }
    const apiRequestCallIds = {
      [this.apiCallIdGetCountryList]: this.getCountryListApiResponse,
      [this.apiCallIdVibeList]: this.vibeListApiResponse,
      [this.apiCallIdProfileInfo]: this.profileInfoApiResponse,
      [this.apiCallIdCityList]: this.cityListApiResponse,
      [this.apiCallIdBannerImagesSeason]: this.bannerImagesApiResponse,
      [this.apiCallIdBannerImagesEnergy]: this.bannerImagesApiResponse,
      [this.apiCallIdBannerImagesSleepingPreference]: this.bannerImagesApiResponse,
      [this.apiCallIdMyInterestSubmit]: this.getMyInterestResponse,
      [this.apiCallIdPlanItinerarySubmit]: this.getPlanItineraryResponse,
      [this.apiCallIdPeriodList]: this.periodListApiResponse


      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }

    // Customizable Area End
  }

  // Customizable Area Start
  apiCallIdGetCountryList: string = '';
  apiCallIdVibeList: string = '';
  apiCallIdCityList: string = '';
  apiCallIdProfileInfo: string = '';
  apiCallIdBannerImagesSeason: string = '';
  apiCallIdBannerImagesEnergy: string = '';
  apiCallIdBannerImagesSleepingPreference: string = '';
  apiCallIdMyInterestSubmit: string = '';
  apiCallIdPlanItinerarySubmit: string = '';
  apiCallIdPeriodList: string = '';

  async componentDidMount() {
    super.componentDidMount();
    this.getCountryListRequest();
    this.getVibeListAPIRequest();
    this.getProfileInfoAPIRequest();
    this.getBannerImagesAPIRequest('season');
    this.getBannerImagesAPIRequest('energy');
    this.getPeriodApiRequest();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  labelShopName: string = "Name of Shop";
  labelAddress: string = "Address";
  labelGstin: string = "GSTIN";
  labelAbout: string = "About Us";
  labelServiceProvided: string = "Services Provided";
  labelDealsIn: string = "Deals in";
  labelShopPhotos: string = "Shop Photos(Min 1 - Max 8)";
  labelVisitingCard: string = "Upload Visiting Card";
  btnLabel: string = "Submit";
  btnContinueLabel: string = "Continue";

  services = [
    { label: "Wholesaler", value: 1 },
    { label: "Manufacturer", value: 2 },
    { label: "Hallmarker", value: 3 },
    { label: "Retailer", value: 4 },
  ];

  getSellerDetail = async (token: string) => {

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      getSellerDetailsMessageId: requestMessage.messageId,
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellersAPIEndPoint + "/1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitSellerDetails = async () => {

    let token = this.state.token;

    if (!token || token.length === 0) {
      this.showAlert("Error", "Invaild Token. Plese log in.")
      return;
    }
    if (
      this.state.shopName.trim() == ""
    ) {
      this.showAlert("Error", configJSON.errorMessageShopName);
      return;
    }

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
    };

    let isWholesalerSelected = false;
    let isRetailerSelected = false;
    let isManufacturerSelected = false;
    let isHallmarking_centerSelected = false;

    this.state.selectedServices.forEach((value) => {
      switch (value) {
        case 1:
          isWholesalerSelected = true;
          break;
        case 2:
          isManufacturerSelected = true;
          break;
        case 3:
          isHallmarking_centerSelected = true;
          break;
        case 4:
          isRetailerSelected = true;
          break;
      }
    });

    const httpBody = {
      token: token,
      seller_account: {
        firm_name: this.state.shopName,
        location: this.state.address,
        gstin_number: this.state.gstin,
        wholesaler: isWholesalerSelected,
        retailer: isRetailerSelected,
        manufacturer: isManufacturerSelected,
        hallmarking_center: isHallmarking_centerSelected,
        lat: this.state.lat,
        long: this.state.long,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      postSellerDetailsMessageId: requestMessage.messageId,
    });


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.sellerID.length > 1 ? configJSON.sellersAPIEndPoint + "/" + this.state.sellerID : configJSON.sellersAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.sellerID.length > 1 ? configJSON.sellerDetailsAPIMethodPUT : configJSON.sellerDetailsAPIMethodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Request Function
  
  getCountryListRequest = () => {
    console.log("request call hocche")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCountryListEndpoint,
      method: "GET",
    });
    this.apiCallIdGetCountryList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCityListAPIRequest = (country: string) => {

    const countryId = this.getCountryIdFunc(country)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `account_block/country/${countryId}/cities`,
      method: "GET",
    });
    this.apiCallIdCityList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryIdFunc = (country: string | null) => {
    return country
      ? (this.state.countryList.length > 0 && this.state.countryList.find(
        (countryData: CountryData) => countryData.name === country
      )?.id)
      : null;
  };

  getCityIdFunc = (city: string | null) => {
    return city
      ? (this.state.cityList.length > 0 && this.state.cityList.find(
        (cityData: CityData) => cityData.name === city
      )?.id)
      : null;
  };


  getVibeListAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.vibeListEndpoint,
      method: "GET",
    });
    this.apiCallIdVibeList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileInfoAPIRequest = async () => {
    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getProfileInfoEndpoint,
      method: "GET",
    });
    this.apiCallIdProfileInfo = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPeriodApiRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.periodListEndpoint,
      method: "GET",
    });
    this.apiCallIdPeriodList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBannerImagesAPIRequest = async (type: string) => {
    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.bannerEndpoint}?banner_type=${type}`,
      method: "GET",
    });

    if(type === 'season'){
    this.apiCallIdBannerImagesSeason = requestMessage.messageId;
    } else if(type === 'energy') {
    this.apiCallIdBannerImagesEnergy = requestMessage.messageId;
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({loading: true})
  }

  handleSubmitMyInterestData = async () => {
    const {selectedCategoryEnergy, selectedCategorySeason, selectedCategorySleeping, selectedCategoryVibes} = this.state;
    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
    };
    
    const formdata = new FormData();

    // Helper function to append a field and value to formdata if the value exists
    const appendFieldIfValueExists = (field: string, value: any) => {
      if (value) {
        formdata.append(field, value);
      }
    };

    // Helper function to append multiple fields with the same prefix
    const appendMultipleFields = (prefix: string, values: any[]) => {
      values.forEach((value) => {
        appendFieldIfValueExists(`${prefix}`, value);
      });
    };

    appendFieldIfValueExists('favorite_season', selectedCategorySeason);
    appendFieldIfValueExists('energy_level', selectedCategoryEnergy);
    appendFieldIfValueExists('period_id', selectedCategorySleeping);
    appendMultipleFields('vibe_ids[]', selectedCategoryVibes);

    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.myInterestEndpoint,
      payload: formdata,
      method: "POST",
    });

    this.apiCallIdMyInterestSubmit = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  submitItineraryPlan = async (values: CombinedItinerary) => {
    console.log(values, "seeing values of submit button");
    const {
      first_name,
      last_name,
      email,
      country_code,
      onlyPhone,
      type_of_help,
      have_date,
      from_date,
      to_date,
      persons,
      know_destination,
      country,
      city,
      have_buget,
      budget_amount,
      vibes,
    } = values;

    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
    };

    const formdataNew = new FormData();

    // Helper function to append a field and value to formdataNew if the value exists
    const appendFieldIfValueExistsNew = (field: string, value: any) => {
      if (value) {
        formdataNew.append(field, value);
      }
    };

    // Helper function to append multiple fields with the same prefix
    const appendMultipleFieldsNew = (prefix: string, values: any[]) => {
      values.forEach((value) => {
        appendFieldIfValueExistsNew(`${prefix}`, value);
      });
    };

    appendFieldIfValueExistsNew('first_name', first_name);
    appendFieldIfValueExistsNew('last_name', last_name);
    appendFieldIfValueExistsNew('email', email);
    appendFieldIfValueExistsNew('contact_number',country_code + onlyPhone);
    appendFieldIfValueExistsNew('help_type', type_of_help);
    appendFieldIfValueExistsNew('have_date', have_date);
    appendFieldIfValueExistsNew('persons', persons);
    appendFieldIfValueExistsNew('know_destination', know_destination);
    appendFieldIfValueExistsNew('have_budget', have_buget);
    appendMultipleFieldsNew('vibes_ids[]', vibes);
    appendFieldIfValueExistsNew('date_from', from_date);
    appendFieldIfValueExistsNew('date_to', to_date);
    appendFieldIfValueExistsNew('city_id', this.getCityIdFunc(city as string));
    appendFieldIfValueExistsNew('country_id', this.getCountryIdFunc(country as string));
    appendFieldIfValueExistsNew('budget_amount', budget_amount);
    appendFieldIfValueExistsNew('currency_id', '2');

    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.planItineraryEndpoint,
      payload: formdataNew,
      method: "POST",
    });

    this.apiCallIdPlanItinerarySubmit = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Response Function
  getCountryListApiResponse = (responseJson: any, errorReponse: any) => {
    console.log("response call hocche")
    const countryDataArray = responseJson.country_data.data;
    if (countryDataArray) {
      const countryList: CountryData[] = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          attributes.id = country.id;
          return attributes;
        }
      );

      this.setState({
        countryList: countryList,
      });
    }
  }

  cityListApiResponse = (responseJson: any, errorReponse: any) => {
    const citiesDataArray = responseJson?.cities_data?.data;
    if (citiesDataArray) {
      const cityList = citiesDataArray.map(
        (city: any) => {
          const attributes = city.attributes;
          attributes.id = city.id;
          return attributes;
        }
      );

      this.setState({
        cityList: cityList,
      });
    }
  }

  vibeListApiResponse = (responseJson: any, errorReponse: any) => {
    const vibeListData = responseJson.data;
    console.log({ vibeListData })
    if (vibeListData) {
      const vibeList: Categories[] = vibeListData.map(
        (vibe: any) => {
          const value = vibe.id;
          const label = capitalizeFirstLetter(vibe.attributes.title);
          return { label, value };
        }
      );

      const personalPreference: IbannerImages[] = vibeListData.filter((data: any) => data.attributes.display_title).map(
        (vibe: any) => {
          return {
            id: vibe.id,
            title: capitalizeFirstLetter(vibe.attributes.display_title),
            bannerType: vibe.type,
            imageUrl: vibe.attributes.image,
            selected: false,
          }
        }
      )

      this.setState({
        vibeList: vibeList,
        personalPreferenceVibes: personalPreference,
      });
    }
  }

  profileInfoApiResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data?.attributes) {
      const customerInfo = responseJson.data.attributes;

      this.setState({
        customer_info: {
          first_name: customerInfo.first_name,
          last_name: customerInfo.last_name,
          email: customerInfo.email,
          country_code: customerInfo.phone_number?.country_code,
          mobile_number: customerInfo.phone_number?.number,
        },
      });
    }
  }

  bannerImagesApiResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data) {
        const bannerImages = responseJson.data.map((item: any) => {
            return {
                id: item.id,
                title: item.attributes.name,
                bannerType: item.attributes.banner_type,
                imageUrl: item.attributes.image,
                selected: false,
            };
        });


        this.setState((prev) => {
            const updatedState = { ...prev };

            bannerImages.forEach((image: IbannerImages) => {
                if (image.bannerType === 'season') {
                    updatedState.season.push(image);
                } else if (image.bannerType === 'energy') {
                    updatedState.energy.push(image);
                }
            });

            return updatedState;
        });
        this.setState({ loading: false });
    }
};

  periodListApiResponse = (responseJson: any, errorReponse: any) => {
    const timeData = responseJson.data;
    if (timeData) {
      const timeList = timeData.map(
        (time: any) => {
          if (time.attributes.display_title) {
            return {
              id: time.id,
              title: time.attributes.display_title,
              bannerType: "sleepingPreference",
              imageUrl: time.attributes.image,
              selected: false,
            };
          } else return null;
        }
      ).filter((item: any) => item !== null);

      this.setState({
        sleepingPreference: timeList,
      });
    }
  }

getMyInterestResponse = (responseJson: any, errorReponse: any) => {

  if (responseJson.messages && !responseJson.error) {
    const msg = responseJson.messages;
    this.setState({
      responseStatsModal: true,
      isSuccessIcon: true,
      responseModalMsg: msg,
    }, () => this.handlePersonalizeNext())
  } else {
    this.setState({
      responseStatsModal: true,
      isSuccessIcon: false,
      responseModalMsg: responseJson.error,
    }, () => this.handlePersonalizeNext())
  }
}

getPlanItineraryResponse = (responseJson: any, errorResponse: any) => {
  if (responseJson.message && !responseJson.error) {
    const msg = responseJson.message;
    this.setState({
      responseStatsModal: true,
      isSuccessIcon: true,
      responseModalMsg: msg,
    }, () => this.handlePersonalizeNext())
  } else {
    this.setState({
      responseStatsModal: true,
      isSuccessIcon: false,
      responseModalMsg: responseJson.error,
    }, () => this.handlePersonalizeNext())
  }
}




  // Internal Function

  handleCategorySeasonSelection = (imageId: string) => {
    const updatedSeason = this.state.season.map((image: IbannerImages) => {
      return { ...image, selected: image.id === imageId ? !image.selected : false };
    });

    const selectedImage = this.state.season.find((item: IbannerImages) => item.id === imageId);
    const selectedTitle = selectedImage?.selected === false ? selectedImage.title.toLocaleLowerCase() : '';


    this.setState({ season: updatedSeason, selectedCategorySeason: selectedTitle })
  };
  
  handleCategoryEnergySelection = (imageId: string) => {
    // Map specific values of "name" to corresponding categories
    const categoryMappings: { [key: string]: string } = {
      'Extermist': 'extremist',
      'So chilled': 'so_chilled',
      'In-between': 'in_between',
      'Flexible': 'flexible'
      // Add more mappings as needed
    };

    const selectedImage = this.state.energy.find((item: IbannerImages) => item.id === imageId);
    let findTitle = this.state.energy?.filter((item: IbannerImages) => item.id === imageId)[0]?.title;
    let category = selectedImage?.selected === false ? categoryMappings[findTitle] : '';
    const updatedEnergy = this.state.energy.map((image: IbannerImages) => {
     return { ...image, selected: image.id === imageId ? !image.selected : false };
    });

    this.setState({energy: updatedEnergy, selectedCategoryEnergy: category})  
  };
  
  handleCategorySleepingSelection = (imageId: string = '') => {
    const selectedImage = this.state.sleepingPreference.find((item: IbannerImages) => item.id === imageId);

    const updatedSleeping = this.state.sleepingPreference.map((image: any) => {
     return { ...image, selected: image.id === imageId ? !image.selected : false };
    });

    this.setState({sleepingPreference: updatedSleeping, selectedCategorySleeping: selectedImage?.selected === false ? imageId : ''}) 
  };
  
  handleCategoryVibesSelection = (imageId: string = '') => {
    const isSelected = this.state.selectedCategoryVibes.includes(imageId);

  if (isSelected) {
    // Deselect if already selected
    const updatedImages = this.state.selectedCategoryVibes.filter((selectedImageId: string) => selectedImageId !== imageId);
    this.setState({ selectedCategoryVibes: updatedImages });
  } else {
    // Select if not already selected
    this.setState((prevState) => ({ selectedCategoryVibes: [...prevState.selectedCategoryVibes, imageId] }));
  }
    const updatedVibes = this.state.personalPreferenceVibes.map((image: any) => {
      if (image.id === imageId) {
        return { ...image, selected: !image.selected };
      } else {
        return image;
      }
    });

    this.setState({personalPreferenceVibes: updatedVibes}) 
  };
  
  onChangeInput = (name: string, value: any) => {
    // @ts-ignore
    this.setState({ [name]: value });
  };

  txtInputProps = (key: string) => ({
    // @ts-ignore
    value: this.state[key],
    onChangeText: (text: string) => {
      this.onChangeInput(key, text);
    },
  });

  inputLabelProps = {
    fontSize: 14,
    color: Colors.inputLabel,
    lineHeight: 16,
  };

  onServiceSelected = (serviceId: number) => {
    if (!this.state.selectedServices.includes(serviceId)) {
      this.setState({
        selectedServices: this.state.selectedServices.concat(serviceId),
      });
    }
  };

  onServiceUnSelected = (serviceId: number) => {
    this.setState({
      selectedServices: this.state.selectedServices.filter(
        (selectedService) => selectedService !== serviceId
      ),
    });
  };

  showModal = () => {
    this.setState({ showSuccessModal: true });
  };

  hideModal = () => {
    this.setState({ showSuccessModal: false }, () => {
    });
  };

  closeResponseModal = () => {
    this.setState({ responseStatsModal: false })
  }
  handlePersonalizeNext = async () => {
    const modalResponseObj = {
      open: this.state.responseStatsModal,
      isSuccessIcon: this.state.isSuccessIcon,
      modalMsg: this.state.responseModalMsg,
    }
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      modalResponseObj
    });
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }

  gotoLandingPage = () => {
    this.props.navigation.navigate("LandingPage")
  }
  // Customizable Area End
}

// Customizable Area Start
import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider, Theme, withStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";

import { primaryColor } from "../../../components/src/theme";
import { whiteCalendarIcon } from "../../ProductDescription/src/assets";
import TripListComponent from "../../../components/src/TripListComponent";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FE7F2D",
      contrastText: "#fff"
    }
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightBold: 600,
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px",
      fontSize: 12, // Custom font size for subtitle1
      fontWeight: 400 // Custom font weight for subtitle1
    }
    // Add more custom typography styles here as needed
  }
});

import CatalogueController, { Props } from "./CatalogueController";
import CustomModal from "../../../components/src/CustumModal";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import { colorCalendar } from "./assets";

export default class TripList extends CatalogueController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const { classes } = this.props;
    
    const {
        tripList,
        countryList_singleTrip,
        totalPages, totalItems,
        page,
        limit,
        isSearched,
        isSuccess_singleTrip,
        modalMsg_singleTrip,
        openModal, 
        responseStatusModal_singleTrip,
        sureDelete_singleTrip,
        tripTypeList,
        loader,
        deletedTripId
    } = this.state;

    const page_options = {totalPages, page, limit, totalItems }


    return (
      <ThemeProvider theme={theme}>
        <div
          style={{
            backgroundColor: "#F6FBFB",
            marginBottom: "90px"
          }}
        >
          <div className={classes.marginStyles}>
          <div
            className={classes.txtBtn}
          >
            <Typography
              className={classes.mainTitle}
            >
              My trips
            </Typography>

            <div
             className={classes.btnBtn}
            >
              <Button
                startIcon={<Add />}
                test-id="navigateToCreateTrip"
                onClick={() => this.navigateToCreateTrip()}
                className={classes.createBtnStyle}
              >
                Add New Trip
              </Button>
              <Button
                startIcon={
                  <img
                    src={colorCalendar}
                    style={{ height: "100%", width: "100%" }}
                    alt=""
                  />
                }
                test-id="navigateToTripCalendar"
                onClick={() => this.navigateToCalendarView()}
                className={classes.clndrBtnStyle}
              >
                Calendar View
              </Button>
            </div>
          </div>

          <Box>
            <TripListComponent 
            isSearched={isSearched}
            countryList={countryList_singleTrip}
            tripList={tripList}
            tripTypeList={tripTypeList}
            loader={loader}
            page_options={page_options}
            handleFilterChange={this.handleFilterChange}
            handleSearchChange={this.handleSearchChange}
            handlePaginationChange={this.handlePaginationChange}
            handleDeleteModal={this.openModal}
            navigation={this.props.navigation} />
          </Box>

                <CustomModal
                    open={openModal}
                    onClose={this.onCloseModal}
                    message={modalMsg_singleTrip}
                    mode="deleteTrip"
                    deleteTripApiRequest={this.deleteTripAPIRequest}
                    sureDelete={sureDelete_singleTrip}
                    handleProceedwithDeleteTripReason={this.handleProceedwithDeleteTripReason}
                    addReason={this.handleDeleteTrip}
                    triplist = {tripList}
                    tripId = {deletedTripId}
                />

                <ResponseStatusModal
                    open={responseStatusModal_singleTrip}
                    isSuccess={isSuccess_singleTrip}
                    message={modalMsg_singleTrip}
                    onClose={this.onCloseModal}
                />
            </div>
        </div>
      </ThemeProvider>
    );
  }
}
export const tripListStyles = (theme: Theme) => ({
  mainTitle: {
      fontFamily: 'Inter',
      fontSize: '32px',
      fontStyle: 'normal',
      lineHeight: 'normal',
      fontWeight: 600,
      color: '#212426',
      // margin: "22px auto 32px", // Center the div horizontally
  },
  breadCrumbStyle: {
      display: 'inline'
  },
  marginStyles: {
      margin: 'none'
  },
  txtBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    marginBottom: "24px"
  },
  btnBtn: {
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "flex-end",
    gap: "24px"
  },
  createBtnStyle: {
    fontFamily: "Inter",
    fontSize: "16px",
    height: "48px",
    fontWeight: 500,
    width: "189px",
    padding: "10px 50px",
    textTransform: "initial" as const,
    whiteSpace: "nowrap" as const,
    borderRadius: "34px",
    border: `1px solid ${primaryColor}`,
    backgroundColor: primaryColor,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: primaryColor,
      color: '#ffffff'
  }
  },
  clndrBtnStyle: {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Inter",
    height: "48px",
    whiteSpace: "nowrap" as const,
    width: "189px",
    padding: "-1px 50px",
    borderRadius: "34px",
    textTransform: "initial" as const,
    backgroundColor: "transparent" as const,
    border: `1px solid ${primaryColor}`,
    color: primaryColor,
  },
  [theme.breakpoints.down(1280)]: {
      marginStyles: {
          margin: '0 48px'
      },
      mainTitle: {
          fontFamily: 'Inter',
          fontSize: '28px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontWeight: 600,
          color: '#212426',
          // margin: "22px auto 32px", // Center the div horizontally
      },
  },
  [theme.breakpoints.down(768)]: {
      marginStyles: {
          margin: '0 16px'
      },
      mainTitle: {
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontWeight: 600,
          color: '#212426',
          // margin: "22px auto 32px", // Center the div horizontally
      },
  },
  [theme.breakpoints.up(1280)]: {
      breadCrumbStyle: {
          display: 'none'
      }
  },
  [theme.breakpoints.down(561)]: {
    txtBtn: {
      display: "flex",
      flexDirection: 'column',
      justifyContent: "space-between",
      alignItems: 'center',
      marginBottom: "24px",
      gap: '24px'
    },
    btnBtn: {
      display: "flex",
      flexDirection: 'row',
      // flexWrap: "wrap" as const,
      justifyContent: "space-between",
      // gap: "24px",
      width: '100%',
    },
    createBtnStyle: {
      fontWeight: 500,
      fontFamily: "Inter",
      fontSize: "16px",
      textTransform: "initial" as const,
      height: "48px",
      padding: "10px 50px",
      width: "150px",
      whiteSpace: "nowrap" as const,
      backgroundColor: primaryColor,
      border: `1px solid ${primaryColor}`,
      borderRadius: "34px",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: primaryColor,
        color: '#ffffff'
    }
    },
    clndrBtnStyle: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 500,
      height: "48px",
      whiteSpace: "nowrap" as const,
      width: "150px",
      textTransform: "initial" as const,
      padding: "-1px 50px",
      backgroundColor: "transparent" as const,
      borderRadius: "34px",
      color: primaryColor,
      border: `1px solid ${primaryColor}`
    },
  }
})
const TripListWeb = withStyles(tripListStyles)(TripList)
export { TripListWeb }
// Customizable Area End
